<template>
    <section class="bill">
        <div style="margin: 10px 0;font-size: 20px">房租</div>
        <el-button size="mini" @click="openDeposit" type="primary">￥充值</el-button>
        <el-tag style="margin-left: 10px;" type="info">房租账户余额：{{rentalBalance}}</el-tag>
        <div>
            <h4>房租（未支付）</h4>
        </div>
        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getLeasorBills" :columns="tableColumn" :height="500">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="150">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <el-button size="mini" style="padding: 5px 10px !important;" @click="addBillSms(row)">催款</el-button>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <r-e-dialog title="房租充值" :visible.sync="dialogVisible" width="750px" @click-submit="clickSubmit"
                    show-footer @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="formPublish" :model="data" label-width="90px" size="small" :rules="rules">
                <el-form-item label="充值金额" prop="rental_amount">
                    <el-input v-model="data.rental_amount" type="number" />
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="data.comment" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" />
                </el-form-item>
            </el-form>
        </r-e-dialog>
    </section>
</template>

<script>
    import {addDeposit, getRent, getLeasorBills} from "@/api/rental-management";
    import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
    import {billsTableColumn} from "@/views/rental-management/data";
    import {addBillSms} from "@/api/alert";
    import {MessageSuccess} from "@custom/message";
    export default {
        name: "bill",
        props: {
            tenantData: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                data: {
                    rental_amount: null,
                    comment: null,
                },
                dialogVisible: false,
                loadingOptions: {
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                },
                rules: {
                    rental_amount: [{required: true, message: '请输入充值金额', trigger: 'blur'}]
                },
                rentalBalance: null,
                tableColumn: billsTableColumn,
            };
        },
        components: {},
        created() {
            this.getRent();
        },
        methods: {
            getLeasorBills(params){
                let {uuid} = this.tenantData;
                let data = {...params};
                return getLeasorBills(uuid,data);
            },
            openDeposit(){
                this.data = {
                    rental_amount: null,
                    comment: null,
                }
                this.dialogVisible = true;
            },
            clickSubmit() {
                let that = this;
                that.$refs["formPublish"].validate((valid) => {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    let {rental_amount,comment} = that.data;
                    rental_amount = ParseIntFormat(rental_amount);
                    let transactionCode = Math.random();
                    const data = {rental_amount, comment, transactionCode};
                    let {uuid} = that.tenantData;
                    addDeposit(uuid,data).then(res=>{
                        MessageSuccess('充值成功');
                        this.clickCancel();
                    }).finally(()=> loading.close());
                });
            },
            clickCancel(){
                this.$emit('handleSearch',false);
                this.data = {
                    rental_amount: null,
                    comment: null,
                }
                this.getRent();
                this.dialogVisible = false;
            },
            getRent(){
                let {uuid} = this.tenantData;
                getRent(uuid).then(res => {
                    let {info:{rental_balance}} = res;
                    this.rentalBalance = ParseFloatFormat(rental_balance);
                });
            },
            addBillSms(data){
                let {uuid} = data;
                const loading = this.$loading({lock: true, text: "请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                addBillSms({uuid}).then(res => {
                    MessageSuccess("发送成功!");
                }).finally(() => loading.close());
            }
        }
    }
</script>

<style scoped>

</style>